body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

body,
html {
  box-sizing: border-box;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

#canvas-container,
canvas {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: lightgray;
  width: 100vw;
  height: 100vh;
}

#canvas-container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

#save-button {
  position: fixed;
  top: 2em;
  right: 2em;
}

#clear-button {
  position: fixed;
  top: 4em;
  right: 2em;
}

#info-panel {
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 100;
  max-width: 300px;
}

#info-panel svg {
  width: 25px;
  height: 25px;
  display: block;
}

#thickness-settings {
  position: fixed;
  top: 6em;
  right: 2em;
  z-index: 100;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 200px;
}

.thickness {
  display: block;
  width: 10px;
  height: 10px;
  background-color: black;
  border: 1px solid black;
}

.thickness.selected {
  border: 1px solid red;
}

.thickness-tiny {
  width: 5px;
  height: 5px;
}

.thickness-medium {
  width: 25px;
  height: 25px;
}

.thickness-large {
  width: 40px;
  height: 40px;
}

#details {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

#details-inner {
  height: 100%;
  width: 60%;
  background-color: white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  #details-inner {
    width: 100%;
    height: 90%;
  }
  #details {
    padding: 0em;
  }
}

#details-inner input {
  font-size: 2em;
  padding: 0.25em;
  margin-bottom: 2em;
  width: 100%;
}

.save-or-cancel {
  text-align: center;
}

#final-save-button {
  width: 100%;
  font-size: 1.25em;
  padding: 0.25em;
  align-self: flex-end;
  margin: 0.5em 0;
}
